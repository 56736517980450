////
/// List styles (ul, ol)
////

@mixin type--list {
    padding-left: $sz--list-padding;
    list-style: none;

    margin-bottom: 24px;

    @include mq('<=md') {
        margin-bottom: 16px;
    }

    > li {
        position: relative;
        display: inline-block;
        width: 100%;

        > * { // stylelint-disable selector-max-universal
            margin-bottom: 0;
        }

        ~ li {
            margin-top: $sz--list-vertical-spacing;
        }

        &::before {
            position: absolute;
            top: 0;
            right: 100%;
            display: inline-block;
            width: $sz--list-padding;
            height: auto;
            font-size: inherit;

            color: currentColor;
        }
    }
}

@mixin type--ul {
    @include type--list;

    padding-left: 28px;

    > li {

        padding-left: 2px;

        &::before {
            margin-top: 4px;
            content: '\25A0';
            font-size: $fz--x-small;
            text-align: center;
            color: $c--brand-secondary;
        }
    }
}

@mixin type--ol {
    @include type--list;
    counter-reset: li;

    padding-left: 30px;

    > li {
        padding-left: 6px;

        &::before {
            content: counter(li) '.';
            counter-increment: li;
            text-align: center;
            color: $c--brand-secondary;
        }
    }
}
